<template>
  <div class="row" style="width:100%;margin:10px auto;display:flex;flex-direction:column;gap:10px">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Update Programmationsusers #{{ formData.id }}</div>
        <div v-if="formState == 'Create'">Create Programmationsusers</div>
        <div v-if="formState == 'AddUsers'">Programmer un agent</div>
      </template>
      <div v-if="formState == 'AddUsers'">
        <form @submit.prevent="addAgent()">
          <div class="form-group">
            <label>users </label>
            <CustomSelect :key="add.user_id" :columnDefs="['nom', 'prenom', 'matricule']"
                          :oldValue="{}" :renderCallBack="(data) => `${data.Selectlabel}`"
                          :selectCallBack="(data) => add.user_id = data.id"
                          :url="`${axios.defaults.baseURL}/api/users-Aggrid1`"
                          filter-key=""
                          filter-value=""/>
            <div v-if="errors.user_id" class="invalid-feedback">
              <template v-for=" error in errors.user_id"> {{ error[0] }}</template>

            </div>
          </div>
          <div v-for="date in periodes" class="form-group">
            <label>{{ showDate(date) }} </label>
            <v-select v-model="add['date'][date]" :options="horairesData" :reduce="ele => ele.id"
                      :value="horairesData[0].id" label="Selectlabel"/>
            <div v-if="errors.user_id" class="invalid-feedback">
              <template v-for=" error in errors.user_id"> {{ error[0] }}</template>

            </div>
          </div>

          <button class="btn btn-primary" type="submit">
            <i class="fas fa-floppy-disk"></i> Créer
          </button>
        </form>

      </div>
      <AgGridSearch v-if="formState == 'AddUsersListings'" :columnDefs="addListing.columnDefs"
                    :extrasData="addListing.extrasData" :filterFields="['nom', 'prenom', 'matricule']"
                    :url="addListing.url"
                    filter-key="type" filter-value="2" @destruction="finishAddUser">
      </AgGridSearch>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div>

    </div>
    <div class="Programmes">

      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :extrasData="extrasData"
                   :maxBlocksInCache="maxBlocksInCache" :pagination="pagination"
                   :paginationPageSize="paginationPageSize"
                   :rowData="rowData" :rowModelType="rowModelType" :show-export="false"
                   :sideBar="false"
                   :defaultColumnsOrder="['clients.libelle','sites.libelle','postes.libelle']"

                   :url="url"
                   className="ag-theme-alpine" domLayout='autoHeight' rowSelection="multiple"
                   @gridReady="onGridReady"
                   @newData="newData">
        <template #header_buttons>

        </template>

      </AgGridTable>
    </div>

  </div>
</template>


<script>
import {mapGetters} from 'vuex';


import moment from 'moment'


import ListingsTraitements from "./ListingsTraitements.vue";
import PostesTraitements from "./PostesTraitements.vue";
import DeclarerPresents from "./Traitements/DeclarerPresents.vue"
import GetPoste from "./Traitements/GetPoste.vue"
import GetSite from "./Traitements/GetSite.vue"
import GetClient from "./Traitements/GetClient.vue"
import GetHoraire from "./Traitements/GetHoraire.vue"
import GetPointages from "./Traitements/GetPointages.vue"
import GetRemplacant from "./Traitements/GetRemplacant.vue"


export default {
  name: 'Readprogrammes',
  props: ['data', 'usersData', 'horairesData', 'filter', 'poste', 'periode'],
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    VSelect: () => import("vue-select"), CustomSelect: () => import("@/components/CustomSelect.vue"),
    ListingsTraitements,
    PostesTraitements,
    AgGridSearch: () => import("@/components/AgGridSearch.vue"),
    Preuves: () => import("./Preuves.vue"),
    DeclarerPresents,
    GetPoste,
    GetSite,
    GetClient,
    GetHoraire,
    GetRemplacant,
    GetPointages
  },
  data() {
    return {
      form: {
        client_id: "",
        poste_id: ""
      },
      add: {
        user_id: 0,
        date: {}
      },
      champsAfficher: [
        //LISTE DES CHAMP à MASQUER
      ],
      errors: {},
      formId: "programmes",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/programmationsusers-Aggrid1',
      table: 'programmationsusers',
      requette: 1,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      allData: [],
      statutsData: [],
      addListing: {
        formId: "listings",
        formState: "",
        formData: {},
        formWidth: 'lg',
        formGridApi: {},
        formKey: 0,
        tableKey: 0,
        url: 'http://127.0.0.1:8000/api/listings-Aggrid1',
        table: 'Users',
        requette: 18,
        columnDefs: null,
        rowData: null,
        gridApi: null,
        columnApi: null,
        rowModelType: null,
        pagination: true,
        paginationPageSize: 100,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        extrasData: {},
      }
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    periodes: function () {
      let date = [this.periode]
      return date;

    },
    columnDefs: function () {
      let data = [
        {
          field: null,

          maxminWidth: 80,
          maxWidth: 80,
          pinned: 'left',
          suppressColumnsToolPanel: true,
          sortable: false,
          headerName: '',
          cellRendererSelector: params => {
            let response = {
              component: 'AgGridBtnClicked',
              params: {
                clicked: field => {
                  this.deleteUser(field)
                },
                render: `<button class="btn btn-warning" > <i class="fa-solid fa-trash "></i></button>`
              }
            }

            return response;
          },
          floatingFilter: true,
        },
        {
          headerName: 'programmes.id',
          field: 'programmes.id',
          hide: true
          , floatingFilter: true,
        },
        {
          headerName: 'Horaires',
          hide: true,
          field: 'horaires.libelle',
          join: {
            table: 'horaires',
            champ1: 'programmes.horaire_id',
            champ2: 'horaires.id',
            operateur: '='
          },
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
        {
          headerName: 'Postes',
          hide: this.isShow('Postes'),
          field: 'postes.libelle',
          join: {
            table: 'postes',
            champ1: 'horaires.poste_id',
            champ2: 'postes.id',
            operateur: '='
          },
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
        {
          headerName: 'programmations',
          hide: true,
          field: 'programmations.libelle',
          // minWidth: 250,
          cellRendererSelector: params => {
            return {
              component: 'GetClient',
              params: {
                clicked: field => {
                  this.showForm('Update', field, params.api)
                },
                etats: 'manuel',
                usersData: this.usersData
              },
            }
          },
          join: {
            table: 'programmations',
            champ1: 'programmes.programmation_id',
            champ2: 'programmations.id',
            operateur: '='
          },
          filter: 'agTextColumnFilter'
          , floatingFilter: true,
        },
        {
          headerName: 'Site',
          hide: this.isShow('Site'),
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          field: 'sites.libelle',
          join: {
            table: 'sites',
            champ1: 'postes.site_id',
            champ2: 'sites.id',
            operateur: '='
          }
          , floatingFilter: true,
        },
        {
          headerName: 'Client',
          hide: this.isShow('Client'),
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          field: 'clients.libelle',
          join: {
            table: 'clients',
            champ1: 'sites.client_id',
            champ2: 'clients.id',
            operateur: '='
          }
          , floatingFilter: true,
        },
        {
          suppressColumnsToolPanel: true,

          headerName: 'Matricule',
          maxWidth: 150,
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          field: 'user.matricule',
          join: {
            table: 'users',
            tableAlias: 'user',
            champ1: 'programmes.user_id',
            champ2: 'users.id',
            operateur: '='
          }
          , floatingFilter: true,
        },
        {
          suppressColumnsToolPanel: true,

          headerName: 'Nom',
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          field: 'user.nom',
          floatingFilter: true,
        },
        {
          suppressColumnsToolPanel: true,
          headerName: 'Prenom',
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          field: 'user.prenom',
          floatingFilter: true,
        },
        {
          headerName: 'Remplacant',
          hide: this.isShow('Remplacant'),
          field: null,
          cellRendererSelector: params => {
            return {
              component: 'GetRemplacant',
              params: {
                clicked: field => {
                  this.showForm('Update', field, params.api)
                },
                etats: 'manuel',
                usersData: this.usersData,
                listingId: this.data.id
              },
            }
          }
          , floatingFilter: true,
        },
        {
          headerName: 'Pointages',
          field: null,
          minWidth: 80,
          maxWidth: 100,
          cellRendererSelector: params => {
            return {
              component: 'GetPointages',
              params: {
                clicked: field => {
                  this.showForm('Update', field, params.api)
                },
                etats: 'manuel',
                usersData: this.usersData
              },
            }
          }
        },
        {
          headerName: `Presents ?`,
          field: null,
          minWidth: 80,
          maxWidth: 100,
          cellRendererSelector: params => {
            return {
              component: 'DeclarerPresents',
              params: {
                clicked: field => {
                  this.showForm('Update', field, params.api)
                },
                etats: 'manuel',
                usersData: this.usersData
              },
            }
          }
        },
        {
          headerName: 'presence_declarer_auto',
          field: 'presence_declarer_auto',
          hide: true
        },
        {
          headerName: 'presence_declarer_manuel',
          field: 'presence_declarer_manuel',
          hide: true
        },
        {
          headerName: 'pointages_rattacher_auto',
          field: 'pointages_rattacher_auto',
          hide: true
        },
        {
          headerName: 'pointages_rattacher_manuel',
          field: 'pointages_rattacher_manuel',
          hide: true
        },
        {
          headerName: 'pointages_debut_auto',
          field: 'pointages_debut_auto',
          hide: true
        },
        {
          headerName: 'pointages_debut_manuel',
          field: 'pointages_debut_manuel',
          hide: true
        },
        {
          headerName: 'pointages_fin_auto',
          field: 'pointages_fin_auto',
          hide: true
        },
        {
          headerName: 'pointages_fin_manuel',
          field: 'pointages_fin_manuel',
          hide: true
        },
        {
          headerName: 'debut_prevu',
          field: 'debut_prevu',
          hide: true
        },
        {
          headerName: 'fin_prevu',
          field: 'fin_prevu',
          hide: true
        },
        {
          headerName: 'debut_reel',
          field: 'debut_reel',
          hide: true
        },
        {
          headerName: 'fin_reel',
          field: 'fin_reel',
          hide: true
        },
        {
          headerName: 'debut_realise',
          field: 'debut_realise',
          hide: true
        },
        {
          headerName: 'fin_realise',
          field: 'fin_realise',
          hide: true
        },
      ];
      return data;

    },
    extrasData: function () {
      let params = {}
      params['programmes.programmation_id'] = {values: [this.data.id], filterType: 'set'}
      // params['order'] = {values: ['order'], filterType: 'set'}

      this.tableKey++
      return {'baseFilter': params}

    },
    testData: function () {
      let data = {}
      try {
        data = this.allData[0]
      } catch (e) {
      }
      return data
    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
    'form.client_id': {
      handler: function (after, before) {
        this.form.poste_id = ''
      },
      deep: true
    },
    'postesData': {
      handler: function (after, before) {
        this.form.poste_id = after[0].id
      },
      deep: true
    },
    'clientsData': {
      handler: function (after, before) {
        this.form.client_id = after[0].id
      },
      deep: true
    },

  },
  created() {

    this.url = this.axios.defaults.baseURL + '/api/programmes-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;
    if (this.requette > 0) {
    }

    this.statutsData = [
      'present',
      'absent',
    ];
  },
  beforeMount() {


  },
  mounted() {
    this.addListing.url = this.axios.defaults.baseURL + '/api/users-Aggrid1',
        this.addListing.rowBuffer = 0;
    this.addListing.rowModelType = 'serverSide';
    this.addListing.columnDefs = [

      {
        field: null,

        width: 100,
        pinned: 'left',
        suppressColumnsToolPanel: true,
        sortable: false,
        headerName: '',
        cellRendererSelector: params => {
          let response = {
            component: 'AgGridBtnClicked',
            params: {
              clicked: field => {
                this.addUser(field)
              },
              render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`

            }
          }
          return response;
        },
      },
      {
        field: "matricule",
        sortable: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        headerName: 'matricule',
      },
      {
        field: "nom",
        sortable: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        headerName: 'nom',
      },
      {
        field: "prenom",
        sortable: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        headerName: 'prenom',
      },
      {
        field: "num_badge",
        sortable: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        headerName: 'num_badge',
      },


    ];
    this.addListing.cacheBlockSize = 50;
    this.addListing.maxBlocksInCache = 2;
  },
  methods: {
    isShow(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      return this.champsAfficher.includes(fieldName); // si le champ existe return prend la valeur *true*
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
      this.gridApi.sizeColumnsToFit();
    },
    showDate(date) {
      return moment(date, 'YYYY-MM-DD').locale('fr-fr').format("dddd") + ' ' + date
    },
    addAgent() {
      let data = []

      Object.keys(this.add.date).forEach(cle => {
        data.push({
          programmation_id: this.data.id,
          user_id: this.add.user_id,
          date: cle,
          horaire_id: this.add.date[cle],
        })

      })

      this.axios.post('/api/programmationsActionAddAgent', data).then((response) => {
        // this.axios.post('/api/programmations/action?action=addAgent', data).then((response) => {
        this.tableKey++
        this.$bvModal.hide(this.formId)
        this.$toast.success('Operation effectuer avec succes')

        // this.$toast.success('Erreur survenue lors de la récuperation')

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
      console.log('voici la data de base', this.add, data)

    },
    deleteUser(element) {
      // console.log('suppression dagent du listing ===>1', element,element.programmation.valider2)

      if (element.programmation.valider2 == null) {

        this.isLoading = true

        let data = {}
        data.id = element.id
        // console.log('suppression dagent du listing ===>4', data)
        this.axios.post('/api/programmes/' + element.id + '/delete', data)
            .then(response => {

              this.isLoading = false
              this.tableKey++
              // this.gridApi.applyServerSideTransaction({
              //   remove: [
              //     element.id
              //   ],
              // });
              this.$toast.success('Operation effectuer avec succes')
            }).catch(error => {
          console.log('error lors de la suppression des users ==>', error)
          this.errors = error.response.data.errors
          this.isLoading = false
          this.$toast.error('Erreur survenue lors de l\'enregistrement')
        })
      } else {

        this.$toast.error('Impossible de modifier un listing deja valider par le chef de zone')
      }

    },
    newData(data) {
      this.allData = data.rowData
    },
    addUser(element) {


      let data = [
        {
          programmation_id: this.data.id,
          user_id: element.id,
          date: this.periodes[0],
          horaire_id: this.poste.horaireId,
        }
      ]

      console.log('voici le programmes', data, element, this.testData)
      this.axios.post('/api/programmationsActionAddAgent', data).then((response) => {
        // this.axios.post('/api/programmations/action?action=addAgent', data).then((response) => {
        this.isLoading = false
        this.$toast.success('Operation effectuer avec succes')
        this.userAdded++


      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })

    },
    finishAddUser() {
      // evenement appeler lorsquon ferme le formulaire dajout des agents
      // on verifie si on ajouter des agents et on met a jour le tableau sinon on ne fait rien
      if (this.userAdded > 0) {
        this.tableKey++
      }
      this.userAdded = 0
    },

  }
}
</script>
<style>

.Programmes .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container {
  min-height: 0px !important;
}

.Programmes .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 0px !important;
}
</style>
