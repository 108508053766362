import { render, staticRenderFns } from "./GetPoste.vue?vue&type=template&id=6df3a82b&scoped=true"
import script from "./GetPoste.vue?vue&type=script&lang=js"
export * from "./GetPoste.vue?vue&type=script&lang=js"
import style0 from "./GetPoste.vue?vue&type=style&index=0&id=6df3a82b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df3a82b",
  null
  
)

export default component.exports